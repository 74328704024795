import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="header">
      <nav className="container navbar">
          <div className="logo">SHARK826</div>
          <div className="menu">
              <ul className="li"><a href="https://blog.shark826.ru" target="_blank">My Blog</a></ul>
              <ul className="li"><a href="https://shark826.ru/passgen" target="_blank">PassGen</a></ul>
              <ul className="li">Sup</ul>
          </div>
      </nav>
      <section className="column-1 comso">
          <h1>Comming soon</h1>
      </section>
      </header>    
    </div>
  );
}

export default App;
